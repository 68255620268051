import { IPaginatedResponse, IPaginationInput } from './common'

export enum MassMessageTemplateStatus {
  draft = 0,
  moderating = 1,
  rejected = 2,
  approved = 3,
}

export interface IMassMessageTemplate {
  id: number
  businessId: number
  status: MassMessageTemplateStatus
  title: string
  message: string
  rejectionReason: string | null
  createdAt: Date
}

export interface IMassMessageTemplateInput {
  id?: number
  title?: string
  message: string
  rejectionReason?: string
  status: MassMessageTemplateStatus
}

export interface IMassMessageFilterInput {
  status?: MassMessageTemplateStatus
  businessId?: number
}

export interface IMassMessageTemplatesByAdminData {
  massMessageTemplatesByAdmin: IPaginatedResponse<IMassMessageTemplate>
}

export interface IMassMessageTemplatesByAdminVariables {
  pagination: IPaginationInput
  filter: IMassMessageFilterInput
}
