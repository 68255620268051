import gql from 'graphql-tag'

export const MASS_MESSAGE_TEMPLATE_FRAGMENT = gql`
  fragment MassMessageTemplateFragment on MassMessageTemplate {
    id
    businessId
    status
    title
    message
    rejectionReason
    createdAt
  }
`

export const MASS_MESSAGE_TEMPLATES_BY_ADMIN = gql`
  query MassMessageTemplatesByAdmin($pagination: PaginationInput!, $filter: MassMessageTemplatesAdminFilterInput) {
    massMessageTemplatesByAdmin(pagination: $pagination, filter: $filter) {
      data {
        ...MassMessageTemplateFragment
      }
      total
    }
  }
  ${MASS_MESSAGE_TEMPLATE_FRAGMENT}
`

export const UPDATE_MASS_MESSAGE_TEMPLATE = gql`
  mutation UpdateMassMessageTemplateByAdminMutation($input: MassMessageTemplateInput!) {
    updateMassMessageTemplateByAdmin(input: $input) {
      ...MassMessageTemplateFragment
    }
  }
  ${MASS_MESSAGE_TEMPLATE_FRAGMENT}
`

export const DELETE_MASS_MESSAGE_TEMPLATE = gql`
  mutation DeleteMassMessageTemplateMutation($id: Int) {
    deleteMassMessageTemplateMutation(id: $id)
  }
`
